<template>
  <div class="z-50 fixed inset-0 font-GTWalsheimPro backdrop-blur-sm">
    <div class="fixed inset-0 bg-black/70"></div>
    <div class="fixed w-full h-full flex items-center justify-center">
      <div class="px-4 w-full sm:max-w-md md:max-w-lg flex flex-col justify-center items-center">
        <div class="my-9 p-4 md:p-9 bg-white rounded w-full">
          <div class="flex flex-col justify-between items-center">
            <div class="pt-4 flex flex-col justify-between items-center">
              <h1 class="font-cooper font-medium text-3xl text-[#090E11] text-center">I’m hiring as a ...</h1>
            </div>

            <div class="pt-8 flex space-x-8">
              <div class="flex flex-col justify-between items-center">
                <button
                  class="w-44 h-44 rounded-lg flex justify-around items-center"
                  :class="[registrationType === 'company' ? 'bg-[#F0386A]/5 border-2 border-[#F0386A]' : 'border border-[#090E11]/20']"
                  @click="
                    () => {
                      registrationType = 'company';
                    }
                  "
                >
                  <img class="w-24 h-24" src="@/assets/images/team-leader.png" alt="company" />
                </button>
                <span class="font-cooper font-medium text-xl text-[#090E11] text-center">A company</span>
              </div>

              <div class="flex flex-col justify-between items-center">
                <button
                  class="w-44 h-44 border rounded-lg flex justify-around items-center"
                  :class="[registrationType === 'individual' ? 'bg-[#F0386A]/5 border-2 border-[#F0386A]' : 'border border-[#090E11]/20']"
                  @click="
                    () => {
                      registrationType = 'individual';
                    }
                  "
                >
                  <img class="w-24 h-24" src="@/assets/images/affiliate.png" alt="individual" />
                </button>
                <span class="font-cooper font-medium text-xl text-[#090E11] text-center">An individual</span>
              </div>
            </div>

            <div class="pt-8 w-full">
              <button @click="proceed" class="w-full bg-brand-primary text-white p-4 rounded-lg">Proceed</button>
            </div>

            <div class="pt-4 text-center text-sm">
              <span @click="$emit('close')" class="cursor-pointer">Cancel</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const registrationType = ref('');
const router = useRouter();
const toast = useToast();

function proceed() {
  if (!['company', 'individual'].includes(registrationType.value)) {
    toast.error('select an option', {
      timeout: 3000,
      hideProgressBar: true,
    });
    return;
  }

  localStorage.setItem('registrationType', registrationType.value);

  router.push({
    name: 'EmployerRegister',
    params: {
      registrationType: registrationType.value,
    },
  });
}
</script>

<style></style>
