<template>
  <DashboardAuthLayout>
    <template #header>
      <img src="@/assets/images/careercolor.svg" alt="Logo" />
    </template>

    <div class="my-9 p-6 md:p-8 bg-white rounded-xl w-full">
      <RegisterDialog v-if="displayRegisterDialog" @close="displayRegisterDialog = false" />
      <div>
        <h1 class="text-brand-black text-[28px] font-semibold leading-[130%]">Sign in to CareerBuddy</h1>
        <div class="flex items-center mb-2">
          <span class="font-normal text-light-gray mr-3 text-[16px]">A new user? </span>
          <a href="#" class="text-brand-black font-medium text-[16px]" @click.prevent="companyRegistration">Create an account</a>
        </div>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="pt-4 mb-2 flex flex-col">
          <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Email Address <is-required /></label>
          <div class="relative">
            <input
              type="email"
              id="email-address-icon"
              class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full p-3 px-4 focus:outline-none outline-none"
              placeholder="example@email.com"
              v-model="details.email"
            />
          </div>
        </div>

        <div class="pt-4 mb-4 flex flex-col">
          <label for="password-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Enter Password <is-required /></label>
          <div class="relative">
            <input
              :type="showPassword ? 'text' : 'password'"
              placeholder="****************"
              id="password-icon"
              class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full pl-4 pr-10 p-3 focus:outline-none outline-none"
              v-model="details.password"
            />
            <div class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
              <EyeSlashSvg v-if="!showPassword" @click="showPassword = true" />
              <TimesSvg v-else @click="showPassword = false" />
            </div>
          </div>
        </div>

        <div class="pt-4" v-if="error != ''">
          <div class="p-2 items-center flex flex-row border-l-4 border-brand-primary-dark bg-brand-primary-dark bg-opacity-10 rounded">
            <span class="flex-grow text-sm font-normal text-[#090E11] font-GTWalsheimPro">{{ error }}</span>
            <TimesSvg class="flex-grow-0 cursor-pointer w-5" />
          </div>
        </div>

        <div>
          <div class="py-2 text-sm">
            <router-link class="ml-1 text-brand-black font-semibold" to="/reset-password">Forgot Password?</router-link>
          </div>
        </div>

        <div class="pt-4">
          <button
            type="submit"
            class="w-full bg-brand-black text-white p-3 px-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
            :disabled="checkDisability"
          >
            {{ loading ? 'Loading...' : 'Log in' }}
            <btn-loader :loading="loading" />
          </button>
        </div>
      </form>
    </div>
  </DashboardAuthLayout>
</template>

<script setup>
import LockSvg from '@/assets/icons/lock.svg?inline';
import EmailSvg from '@/assets/icons/email.svg?inline';
import EyeSlashSvg from '@/assets/icons/eye-slash-outline.svg?inline';
import TimesSvg from '@/assets/icons/times.svg?inline';
import DashboardAuthLayout from '@/layouts/AuthLayout.vue';
import RegisterDialog from '@/components/RegisterDialog.vue';
import IsRequired from '@/components/IsRequired.vue';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';

const displayRegisterDialog = ref(false);
const showPassword = ref(false);
const error = ref('');
const router = useRouter();
const disabled = ref(true);
const loading = ref(false);
const submittingForm = ref(false);
const store = useStore();
const details = ref({
  email: '',
  password: '',
  auth_type: 'email',
  auth_type_id: '',
  type: 'employer',
  auth_type: 'email',
});
const checkDisability = computed(() => {
  if (details.value.email === '' || details.value.password === '') {
    disabled.value = true;
  } else if (submittingForm.value === true) {
    disabled.value = true;
  } else {
    disabled.value = false;
  }
  return disabled.value;
});

const companyRegistration = () => {
  router.push({
    name: 'EmployerRegister',
    params: {
      registrationType: 'company',
    },
  });
};

async function handleSubmit() {
  try {
    submittingForm.value = true;
    loading.value = true;
    error.value = '';
    const { user, message } = await store.dispatch('auth/login', details.value);

    //request otp and redirect to otp verify page for unverified users
    if (!user.emailIsVerified) {
      await store.dispatch('auth/requestOtp');
      return router.push('/register-verify');
    }
    alert(message, 'success');

    return router.push('/dashboard');
  } catch (error) {
    process.env.NODE_ENV === 'development' ? console.warn(error) : '';
    loading.value = false;
    submittingForm.value = false;
    if (!error) {
      alert('Request was not sent, not enough data to tell why.', 'error');
    } else {
      alert(errorMessage(error) || 'Something went wrong (Please check internent connection)', 'error', 5000);
    }
  }
}
</script>

<style></style>
